/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';
import getPriceFromEngine from '../../../../../../apis/product-apis/priceEngine';

class DynamicPrice {
    /**
     * Private objects
     */
    #payload = {};

    #response = []

    /**
     *
     * @param {*} skus - List of skus of current product that we need to pass to dynamic price API.
     * @param {*} enterpriseId - it's required
     * @param {*} isSubscriptionProduct - if current product is subscription product
     * @param {*} subscriptionType  -  Subscription type of current product
     */
    constructor(skus, enterpriseId, isSubscriptionProduct = false, subscriptionType = null, shouldBatch = false, variantList = []) {
        this.skus = skus;
        this.enterpriseId = enterpriseId;
        this.isSubscriptionProduct = isSubscriptionProduct;
        this.subscriptionType = subscriptionType;
        this.shouldBatch = shouldBatch;
        this.variantList = variantList;
        this.buildPayload();
    }

    /**
     * @param {*} priceList - array of price
     * @returns sale price of Item based on it's type
     */
    getSalePrice = (priceList) => {
        const price = (priceList || []).find((item) => (item.type === 'sale'));
        return price?.total || price?.value || 0;
    };

    /**
     *
     * @param {*} priceList - array of price
     * @returns Retail price of Item based on type
     */
    getRetailPrice = (priceList) => {
        const price =  (priceList || []).find((item) => (item.type === 'retail'));
        return price?.total || price?.value || 0;
    }

    /**
     *
     * @param {*} priceList - array of price
     * @returns Display retail price of Item based on type
     */
    getRetailDisplayPrice = (priceList) => {
        const price = (priceList || []).find((item) => (item.type === 'retail'));
        return price?.total || price?.display || 0;
    };

    /**
     *
     * @param {*} priceList - array of price
     * @returns Display Sale display price of Item based on type
     */
    getSaleDisplayPrice = (priceList) => {
        const price = (priceList || []).find((item) => (item.type === 'sale'));
        return price?.total || price?.display || 0;
    }

    /**
     *
     * @param {*} sku - Selected sku item
     * @returns Price rule ID from the dynamic pricing API call if entry exists else return null
     */
    getPriceRules = (sku) => {
        if (sku && this.#response.length > 0) {
            return this.#response.find((skuItem) => skuItem.id === sku)?.priceRules || null;
        }
        return null;
    }

    /**
     * @returns void
     * This method set the request payload in private variable of class based on construction params, Currently
     * it's supporting for subscription type attribute and without any attribute
     */
    buildPayload = () => {
        const products = [];
        if (this.isSubscriptionProduct) {
            this.skus.forEach((sku) => {
                products.push({
                    partNumber: sku.id,
                    attributes: [
                        {
                            name: 'SUBSCRIPTION_TYPE',
                            value: this.subscriptionType, // use the subscription type value based on sku
                        },
                    ],
                });
            });
        } else {
            this.skus.forEach((sku) => {
                products.push({
                    partNumber: sku.id,
                });
            });
        }
        this.#payload = {
            enterpriseId: this.enterpriseId,
            products,
            variants: this.variantList,
        };
    }

    /**
     *
     * @returns null || array of dynamic pricing include prices: array, id: string, priceRules: string
     */
    async call(withZipcode, zipcode) {
        // NOTE: always set this new so you dont request with old zipcode stored in this class
        if (withZipcode) {
            this.#payload.variants = [{
                name: 'ZIP_CODE',
                value: zipcode,
            }];
        }

        try {
            const res = await getPriceFromEngine({}, null, this.#payload, this.shouldBatch);
            const newPrices = (res?.data?.products || []).map((productSkuItem) => {
                const prices = productSkuItem.prices;
                const id = productSkuItem.partNumber;
                const priceRules = productSkuItem.priceRules;
                return {
                    prices,
                    id,
                    priceRules,
                    ...productSkuItem,
                };
            });
            this.#response = newPrices;
            return newPrices;
        } catch (error) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                jsError: error,
                message: 'GraphqlPDPSkus-DynamicPrice fetch sku prices failed"',
                enterpriseId: this.enterpriseId,
                partNumber: JSON.stringify(this.payload),
            });
            return [];
        }
    }
}

export default DynamicPrice;
